var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "1400" },
      model: {
        value: _vm.show,
        callback: function($$v) {
          _vm.show = $$v
        },
        expression: "show"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "headline" },
            [
              _c(
                "v-icon",
                { staticClass: "mr-2", attrs: { color: "indigo" } },
                [_vm._v("mdi-database-check")]
              ),
              _vm._v(" Imported Nanotasks for '" + _vm._s(_vm.template) + "' "),
              _c("v-spacer"),
              _c("v-text-field", {
                attrs: {
                  "append-icon": "mdi-magnify",
                  label: "Search",
                  "single-line": "",
                  "hide-details": ""
                },
                model: {
                  value: _vm.search,
                  callback: function($$v) {
                    _vm.search = $$v
                  },
                  expression: "search"
                }
              })
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "text-end" },
            [
              _vm.selectedNanotaskIds.length > 0
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        staticClass: "mx-3",
                                        attrs: {
                                          dark: "",
                                          color: "grey darken-2"
                                        },
                                        on: {
                                          click: function($event) {
                                            _vm.$refs.dialogManageNumAssignments.shown = true
                                          }
                                        }
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _c("v-icon", [
                                      _vm._v("mdi-ticket-confirmation")
                                    ])
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        1434004834
                      )
                    },
                    [_c("span", [_vm._v("Manage # of assignable tickets")])]
                  )
                : _vm._e(),
              _vm.selectedNanotaskIds.length > 0
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        staticClass: "mx-3",
                                        attrs: { dark: "", color: "error" },
                                        on: {
                                          click: function($event) {
                                            return _vm.$refs.dialogDelete.show()
                                          }
                                        }
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [_c("v-icon", [_vm._v("mdi-delete")])],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        2318326084
                      )
                    },
                    [_c("span", [_vm._v("Delete")])]
                  )
                : _vm._e()
            ],
            1
          ),
          _c("v-data-table", {
            attrs: {
              loading: _vm.loading,
              dense: "",
              headers: _vm.headers,
              items: _vm.nanotasksFlat,
              "item-key": "NanotaskId",
              search: _vm.search,
              "footer-props": {
                showFirstLastPage: true,
                firstIcon: "mdi-chevron-double-left",
                lastIcon: "mdi-chevron-double-right",
                prevIcon: "mdi-chevron-left",
                nextIcon: "mdi-chevron-right",
                itemsPerPageOptions: [10, 30, 50, 100, -1]
              },
              "show-select": "",
              "sort-by": "NanotaskId"
            },
            scopedSlots: _vm._u([
              {
                key: "item.ReferenceAnswers",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c("v-simple-table", {
                      attrs: { dense: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function() {
                              return [
                                _c(
                                  "tbody",
                                  _vm._l(item.ReferenceAnswers, function(
                                    value,
                                    key
                                  ) {
                                    return _c("tr", { key: key }, [
                                      _c(
                                        "td",
                                        { staticStyle: { width: "100px" } },
                                        [_c("b", [_vm._v(_vm._s(key))])]
                                      ),
                                      _c(
                                        "td",
                                        {
                                          staticStyle: {
                                            "word-break": "break-all"
                                          }
                                        },
                                        [_vm._v(_vm._s(value))]
                                      )
                                    ])
                                  }),
                                  0
                                )
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        true
                      )
                    })
                  ]
                }
              },
              {
                key: "item.Props",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c("v-simple-table", {
                      attrs: { dense: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function() {
                              return [
                                _c(
                                  "tbody",
                                  _vm._l(item.Props, function(value, key) {
                                    return _c("tr", { key: key }, [
                                      _c(
                                        "td",
                                        { staticStyle: { width: "100px" } },
                                        [_c("b", [_vm._v(_vm._s(key))])]
                                      ),
                                      _c(
                                        "td",
                                        {
                                          staticStyle: {
                                            "word-break": "break-all"
                                          }
                                        },
                                        [_vm._v(_vm._s(value))]
                                      )
                                    ])
                                  }),
                                  0
                                )
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        true
                      )
                    })
                  ]
                }
              },
              {
                key: "item.Timestamp",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm.unixTimeToDatetimeString(item.Timestamp)) +
                        " "
                    )
                  ]
                }
              }
            ]),
            model: {
              value: _vm.selectedNanotasks,
              callback: function($$v) {
                _vm.selectedNanotasks = $$v
              },
              expression: "selectedNanotasks"
            }
          }),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                { attrs: { text: "" }, on: { click: _vm.closeDialog } },
                [_vm._v("Close")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("tutti-dialog", {
        ref: "dialogDelete",
        attrs: {
          title: "Delete Nanotasks",
          maxWidth: "350",
          actions: [
            { label: "Delete", color: "error", onclick: _vm.deleteNanotasks },
            { label: "Cancel", color: "grey darken-1", text: true }
          ]
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function() {
              return [
                _vm._v(
                  " Are you sure you wish to delete the selected nanotasks? This operation cannot be undone. "
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("tutti-dialog", {
        ref: "dialogManageNumAssignments",
        attrs: {
          title: "Manage NumAssignable",
          maxWidth: "350",
          actions: [
            {
              label: "Confirm",
              color: "indigo darken-1",
              text: true,
              onclick: _vm.updateNumAssignable
            },
            { label: "Cancel", color: "grey darken-1", text: true }
          ]
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function() {
              return [
                _c("v-select", {
                  attrs: {
                    items: [
                      { text: "Fixed value", value: "fixed", default: true },
                      { text: "Increment by", value: "increment" }
                    ]
                  },
                  model: {
                    value: _vm.numAssignableMethod,
                    callback: function($$v) {
                      _vm.numAssignableMethod = $$v
                    },
                    expression: "numAssignableMethod"
                  }
                }),
                _c("v-text-field", {
                  attrs: { type: "number", step: "1" },
                  model: {
                    value: _vm.numAssignableValue,
                    callback: function($$v) {
                      _vm.numAssignableValue = _vm._n($$v)
                    },
                    expression: "numAssignableValue"
                  }
                })
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }